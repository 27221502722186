import { Auth } from 'aws-amplify';
import { ROLES } from '../shared/constants';
import {
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';

export async function signInWithEmailAndPassword(
  email: string,
  password: string
): Promise<any> {
  try {
    return await Auth.signIn(email, password);
  } catch (error) {
    return error;
  }
}

export async function signUpWithEmailAndPassword(
  email: string,
  password: string,
  roleID: number
): Promise<any> {
  try {
    return await Auth.signUp({
      username: email,
      password: password,
      attributes: {
        email: email,
        'custom:role_id': roleID.toString(),
      },
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function forgotPassword(email: string): Promise<any> {
  try {
    return await Auth.forgotPassword(email);
  } catch (error) {
    return error;
  }
}

export async function forgotPasswordSubmit(
  email,
  code,
  newPassword
): Promise<any> {
  try {
    return await Auth.forgotPasswordSubmit(email, code, newPassword);
  } catch (error) {
    return error;
  }
}

export async function changePassword(
  oldPassword: string,
  newPassword: string
): Promise<any> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.changePassword(user, oldPassword, newPassword);
  } catch (error) {
    return error;
  }
}

export async function signOut(): Promise<any> {
  try {
    return await Auth.signOut();
  } catch (error) {
    return error;
  }
}

export async function verifyCurrentUserEmail(): Promise<any> {
  try {
    return await Auth.verifyCurrentUserAttribute('email');
  } catch (error) {
    return error;
  }
}

export async function verifyCurrentUserEmailSubmit(code: string): Promise<any> {
  try {
    return await Auth.verifyCurrentUserAttributeSubmit('email', code);
  } catch (error) {
    return error;
  }
}

export async function getCurrentSession(): Promise<any> {
  try {
    return await Auth.currentSession();
  } catch (error) {
    return error;
  }
}

export async function changeEmail(newEmail: string): Promise<any> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.updateUserAttributes(user, { email: newEmail });
  } catch (error) {
    return error;
  }
}

export async function changeRol(): Promise<any> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.updateUserAttributes(user, { 'custom:role_id': '1' });
  } catch (error) {
    return error;
  }
}

export async function getCurrentUser(): Promise<any> {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    console.log('Usuario no autenticado');
    return error;
  }
}

export function injectTokensIntoAmplify(
  accessToken: string,
  idToken: string,
  refreshToken: string
) {
  const session = new CognitoUserSession({
    IdToken: new CognitoIdToken({
      IdToken: idToken,
    }),
    RefreshToken: new CognitoRefreshToken({
      RefreshToken: refreshToken,
    }),
    AccessToken: new CognitoAccessToken({
      AccessToken: accessToken,
    }),
  });
  Auth.Credentials.set(session, 'session');
  const currentUser = (Auth as any).createCognitoUser(
    session.getIdToken().decodePayload()['cognito:username']
  );
  currentUser.setSignInUserSession(session);
}

export function userUseSocialLogin(username: string): boolean {
  return username.includes('google');
}

export async function getCurrentUserRole(): Promise<number> {
  const currentUser = await Auth?.currentAuthenticatedUser();

  if (currentUser?.attributes['custom:role_id']) {
    return Number(currentUser?.attributes['custom:role_id']);
  }

  if (userUseSocialLogin(currentUser.username)) {
    return ROLES.TOURIST;
  }

  return ROLES.ADMIN;
}
