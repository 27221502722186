import axios from 'axios';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';

axios.defaults.baseURL = process.env.NEXT_PUBLIC_AUTH_CONFIG_SERVICE_URL;

axios.interceptors.response.use(
  (response) => response, // Retorna la respuesta si no hay error
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // Redirige a la página deseada en caso de un error 401 o 403
      //TODO: volver a habilitar
      window.location.href = '/';
      return Promise.reject('No está autorizado.');
    } else {
      // Si no tiene `error.response`, loguea el error completo para diagnóstico
      console.error('Error de red o de servidor:', error);
    }

    return Promise.reject(error); // Rechaza el error para que puedas manejarlo donde sea necesario
  }
);

async function getRequestHeaders() {
  try {
    const gtoken = localStorage.getItem('x-token');
    const currentSession = await Auth.currentSession();

    return {
      Authorization: 'Bearer ' + currentSession.getAccessToken().getJwtToken(),
      'twb-cog': currentSession.getIdToken().getJwtToken(),
      ...(gtoken && { 'x-guest-token': gtoken }),
    };
  } catch (error) {
    return {};
  }
}

export async function apiRestGet(path: string, body: any = {}): Promise<any> {
  try {
    const requestOptions: any = {};
    requestOptions.headers = await getRequestHeaders();
    requestOptions.params = body;

    if (body.guest) {
      const gtoken = localStorage.getItem('x-token');
      requestOptions.headers = {
        ...requestOptions.headers,
        'x-guest-token': gtoken,
      };
    }

    const response = await axios.get(path, requestOptions);
    return response.data;
  } catch (error) {
    return error.response?.data;
  }
}

export async function apiRestGetMaps(path: string, body = {}): Promise<any> {
  try {
    const requestOptions: any = {};
    requestOptions.params = body;

    const response = await axios.get(path, requestOptions);
    return response.data;
  } catch (error) {
    return error.response?.data;
  }
}

export async function apiRestPost(path: string, body: any = {}): Promise<any> {
  try {
    const requestOptions: any = {};
    requestOptions.headers = await getRequestHeaders();

    if (body.guest) {
      const gtoken = localStorage.getItem('x-token');
      requestOptions.headers = {
        ...requestOptions.headers,
        'x-guest-token': gtoken,
      };
    }

    const response = await axios.post(path, body, requestOptions);
    return response.data;
  } catch (error) {
    return { error: error };
  }
}

export async function apiRestPatch(path: string, body: any): Promise<any> {
  try {
    const requestOptions: any = {};
    requestOptions.headers = await getRequestHeaders();

    if (body.guest) {
      const gtoken = localStorage.getItem('x-token');
      requestOptions.headers = {
        ...requestOptions.headers,
        'x-guest-token': gtoken,
      };
    }

    const response = await axios.patch(path, body, requestOptions);
    return response.data;
  } catch (error) {
    return { error: error };
  }
}

export async function apiRestDelete(path: string, body: any): Promise<any> {
  try {
    let requestHeaders = await getRequestHeaders();

    if (body.guest) {
      const gtoken = localStorage.getItem('x-token');
      requestHeaders = { ...requestHeaders, 'x-guest-token': gtoken };
    }

    const response = await axios.delete(path, {
      headers: requestHeaders,
      data: body,
    });
    return response.data;
  } catch (error) {
    return { error: error };
  }
}
